import { memo as Memo } from 'react'

//* Components
import Page from 'components/common/Page'
import Container from 'components/common/Container'
import Image from 'components/common/Image'
import Text from 'components/common/Text'

//* Style
import Custom404Style from 'styles/pages/Custom404Style'

const Custom404 = Memo(() => {
    return (
        <Page className={`error-404`}>
            <Custom404Style>
                <Container>
                    <div className="content-wrap">
                        <Image
                            className={'image-404'}
                            src={'/images/404.svg'}
                        />
                        <Text className={'h2 font-reckless-neue-semibold title-404'} text={'pageNotFound'} />
                        <Text className={'p p2 font-jakarta-sans-medium description-404'} text={'pageNotFoundDescription'} />
                    </div>
                </Container>
            </Custom404Style>
        </Page>
    )
})

export default Custom404
