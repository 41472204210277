import styled from 'styled-components'

const Custom404Style = styled.section`
	--titleMarginTopBottom: var(--sp9x) 0;
	--description404MaxWidth: var(--sp120x);

	width: 100%;

	.content-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.image-404 {
			width: 41.3%;
			padding-top: 16.1%;

            img {
                object-fit: contain;
            }
		}

		.title-404 {
			margin: var(--titleMarginTopBottom);
		}

		.description-404 {
			text-align: center;
			max-width: var(--description404MaxWidth);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--titleMarginTopBottom: var(--sp7x) 0;
		--description404MaxWidth: var(--sp110x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--titleMarginTopBottom: var(--sp6x) 0;
		--description404MaxWidth: var(--sp100x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--titleMarginTopBottom: var(--sp6x) 0;
		--description404MaxWidth: var(--sp100x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--titleMarginTopBottom: var(--sp5x) 0;
		--description404MaxWidth: var(--sp80x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--titleMarginTopBottom: var(--sp4x) 0;
		--description404MaxWidth: var(--sp80x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--titleMarginTopBottom: var(--sp4x) 0;
		--description404MaxWidth: var(--sp75x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--titleMarginTopBottom: var(--sp3x) 0;
		--description404MaxWidth: var(--sp50x);
	}
`

export default Custom404Style
